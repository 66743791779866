<template>
	<div class="w-80 hidden lg:block">
		<NuxtLink
			v-if="shouldShow"
			:to="url"
			:target="target"
			class="rounded-3xl">
			<ButtonPrimary>
				<p>{{ $t("discoverMore") }}</p>
			</ButtonPrimary>
		</NuxtLink>
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const url = runtimeConfig.public.BACK_TO_SITE.URL
	const target = runtimeConfig.public.BACK_TO_SITE.TARGET
	const shouldShow = !runtimeConfig.public.IS_KIOSK_APP
</script>

<style lang="scss" scoped></style>
